<template>
<svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M63.9974 10.6667C34.5414 10.6667 10.6641 34.5441 10.6641 64.0001C10.6641 93.4561 34.5414 117.333 63.9974 117.333C93.4534 117.333 117.331 93.4561 117.331 64.0001C117.331 34.5441 93.4534 10.6667 63.9974 10.6667ZM63.9974 21.3334C87.5601 21.3334 106.664 40.4374 106.664 64.0001C106.664 87.5627 87.5601 106.667 63.9974 106.667C40.4347 106.667 21.3307 87.5627 21.3307 64.0001C21.3307 40.4374 40.4347 21.3334 63.9974 21.3334ZM45.3307 42.6667C40.9147 42.6667 37.3307 46.2507 37.3307 50.6667V53.3334H53.3307V50.6667C53.3307 46.2507 49.7467 42.6667 45.3307 42.6667ZM82.6641 42.6667C78.2481 42.6667 74.6641 46.2507 74.6641 50.6667V53.3334H90.6641V50.6667C90.6641 46.2507 87.0801 42.6667 82.6641 42.6667ZM36.7474 64.0001C41.0141 81.0987 51.5707 93.3334 63.9974 93.3334C76.4241 93.3334 86.9807 81.0987 91.2474 64.0001H36.7474Z" fill="url(#paint0_linear_210:1386)"/>
<defs>
<linearGradient id="paint0_linear_210:1386" x1="11" y1="10.9999" x2="117" y2="117" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="#88888A"/>
</linearGradient>
</defs>
</svg>
</template>