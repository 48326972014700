<template>
<svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M63.9991 2.56006C41.4191 2.56006 23.0391 20.9401 23.0391 43.5201C23.0391 79.6501 60.4891 122.74 62.0791 124.56C62.5691 125.12 63.2591 125.44 63.9991 125.44C64.7891 125.39 65.4291 125.12 65.9191 124.56C67.5091 122.71 104.959 78.8801 104.959 43.5201C104.959 20.9401 86.5791 2.56006 63.9991 2.56006ZM63.9991 30.7201C72.4791 30.7201 79.3591 37.6001 79.3591 46.0801C79.3591 54.5601 72.4791 61.4401 63.9991 61.4401C55.5191 61.4401 48.6391 54.5601 48.6391 46.0801C48.6391 37.6001 55.5191 30.7201 63.9991 30.7201Z" fill="url(#paint0_linear_120:7599)"/>
<defs>
<linearGradient id="paint0_linear_120:7599" x1="23.2971" y1="2.9439" x2="136.016" y2="78.0897" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0.4"/>
</linearGradient>
</defs>
</svg>
</template>