<template>
  <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_702_4395)">
      <path d="M118.777 0H31.2225C22.9418 0 15.0002 3.2895 9.14486 9.14486C3.2895 15.0002 0 22.9418 0 31.2225L0 118.777C0 127.058 3.2895 135 9.14486 140.855C15.0002 146.71 22.9418 150 31.2225 150H118.777C127.058 150 135 146.71 140.855 140.855C146.71 135 150 127.058 150 118.777V31.2225C150 22.9418 146.71 15.0002 140.855 9.14486C135 3.2895 127.058 0 118.777 0V0Z" fill="#182DA8"/>
      <path d="M132 73.7308C127.284 80.6112 119.187 84.9804 111.831 84.9804C109.034 85.0204 106.275 84.3358 103.822 82.9931H103.488C104.973 85.0541 106.92 86.7379 109.174 87.9092C111.428 89.0806 113.925 89.7068 116.465 89.7375C119.61 89.7801 122.663 88.6744 125.052 86.6273L125.188 86.7634C120.056 98.0212 110.728 106.828 99.1943 111.304C100.446 115.388 101.971 122.152 101.971 126.957C101.971 129.938 101.379 131.986 99.4597 131.986C95.3768 131.986 86.5307 122.329 83.9448 118.089C87.355 116.904 90.6544 115.422 93.8049 113.659V113.332C88.8036 114.563 83.6719 115.185 78.5215 115.183C70.3558 115.183 62.1152 113.659 55.2356 110.352C58.6574 104.898 61.2016 98.9409 62.7753 92.6979H62.4486C51.7992 109.834 31.8273 132 21.4364 132C19.2521 132 18 131.013 18 128.495C18 122.009 26.5944 107.922 36.9172 95.5494C32.2832 89.6558 29.3775 81.1897 29.3775 71.798C29.3775 49.6323 45.7089 38.1241 42.674 38.1241C39.0947 38.1241 29.5068 56.9142 21.0417 56.9142C18.8642 56.9142 18 55.662 18 53.3413C18.034 41.8127 39.3533 18 50.1797 18C52.4933 18 53.2894 19.0481 53.2894 20.7222C53.2894 24.8872 48.0634 32.7 43.5586 37.1917H43.8989C55.4057 25.4861 66.2525 18.0136 71.6214 18.0136C73.4043 18.0136 74.2617 18.8643 74.2617 20.3956C74.2617 28.1267 52.0374 56.9074 42.5652 56.9074C41.4424 56.9074 40.9116 56.5127 40.9116 55.7777C40.9116 52.1435 53.5412 40.4992 53.5412 38.4507C53.5412 38.2466 53.4187 38.1172 53.0853 38.1172C49.7714 38.1172 32.3104 58.8878 32.3104 66.5033C32.3104 68.1502 33.1746 69.2255 35.2909 69.2255C52.153 69.2255 81.6584 18.0272 111.164 18.0272C126.441 18.0272 131.993 31.8493 131.993 44.0857C131.993 52.7492 129.217 66.1834 129.217 69.8176C129.224 71.7095 130.02 72.7848 132 73.7308Z" fill="#FFFEF8"/>
      <path d="M104.483 75.5474C104.505 76.45 104.168 77.3246 103.547 77.9797C102.926 78.6347 102.07 79.0169 101.168 79.0424H100.988C100.067 79.0687 99.1737 78.729 98.5032 78.0978C97.8326 77.4666 97.4396 76.5953 97.4102 75.6749V75.5474C97.4081 74.6137 97.7751 73.717 98.4311 73.0526C99.0871 72.3881 99.979 72.0098 100.913 71.9999H100.988C101.443 71.9959 101.894 72.0816 102.316 72.2521C102.738 72.4226 103.122 72.6745 103.447 72.9935C103.771 73.3125 104.03 73.6923 104.207 74.1112C104.385 74.53 104.479 74.9799 104.483 75.4349V75.5474Z" fill="#182DA8"/>
    </g>
    <defs>
      <clipPath id="clip0_702_4395">
        <rect width="150" height="150" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
