<template>
<svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41.4482 15C25.426 15 19.7573 17.9523 18.1271 19.1239C17.9289 19.2332 17.7453 19.3816 17.5984 19.5613C17.5764 19.5613 17.5617 19.5613 17.5397 19.5613L17.481 19.6862C17.4149 19.7409 17.3561 19.8034 17.3047 19.8737L17.011 20.1236V20.6235C16.9963 20.7484 16.9963 20.8734 17.011 20.9984V112.973C17.0037 113.692 17.3561 114.363 17.9436 114.731C18.531 115.09 19.2506 115.09 19.838 114.731C20.4255 114.363 20.7779 113.692 20.7706 112.973V76.2957C22.9661 75.3507 29.6114 72.9841 41.4482 72.9841C48.0935 72.9841 53.1307 74.5618 58.9536 76.3582C66.0175 78.5373 74.0139 80.982 86.5629 80.982C99.5524 80.982 109.634 74.9914 110.06 74.7337L111 74.1713V19.6237L108.239 21.2483C105.889 22.5995 97.3789 26.9967 86.5629 26.9967C77.5825 26.9967 71.3337 24.1303 64.7104 21.1233C58.0431 18.1007 51.1408 15 41.4482 15Z" fill="url(#paint0_linear_120:7605)"/>
<defs>
<linearGradient id="paint0_linear_120:7605" x1="17.296" y1="15.3124" x2="116.481" y2="108.546" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0.4"/>
</linearGradient>
</defs>
</svg>
</template>